<template>
  <component :is="getComponent" />
</template>
<script>
export default {
  name: 'OrderHistory',
  components: {
    OrderHistoryList: () => import ('./List'),
    OrderHistoryDetails: () => import ('./Details'),
  },
  computed: {
    getComponent() {
      switch (this.$route.name) {
        case 'OrderHistoryList':
          return 'OrderHistoryList';
        case 'OrderHistoryDetails':
          return 'OrderHistoryDetails';
        default:
          return 'OrderHistoryList';
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.page-title {
  color: var(--primary-black-color);
  font-size: 1.75rem;
  font-weight: 600;
}
.order-wrap {
  background-color: #fff;
  border: 5px solid var(--primary-black-color);
  border-radius: 5px;
}
</style>